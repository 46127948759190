import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./api";

const initialState = {
  info: [],
  status: null,
  error: null,
};

export const fetchInfo = createAsyncThunk('info/fetchInfo', async () => {
  try {
    const response = await axios.get(`${url}/site-settings`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const siteInfoSlice = createSlice({
  name: "siteInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInfo.pending, (state) => {
      state.status= "pending";
    });
    builder.addCase(fetchInfo.fulfilled, (state, action) => {
      state.status = 'success';
      state.info = action.payload;
    });
    builder.addCase(fetchInfo.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
});

export default siteInfoSlice.reducer;

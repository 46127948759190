import React from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumbs = (props) => {
    const { bread } = props;
    const { title, product } = bread;
    return (
        <div className="my-5 lg:my-10 px-4 md:px-10 2xl:px-20">
            <div className="container mx-auto">
                <nav className="text-sm font-['Lato'] text-black">
                    <ol className="flex flex-wrap gap-3 items-center">
                        <li className="hover:underline">
                            <NavLink to="/">{title}</NavLink>
                        </li>
                        <li className="before:content-['/'] before:pr-2">
                            <NavLink className="hover:underline" to="/products">
                                {product}
                            </NavLink>
                        </li>
                        {/* <li className="before:content-['/'] before:pr-2 font-semibold">Cart</li> */}
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumbs;

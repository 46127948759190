import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

const Pagination = ({ totalData, onPageChange, data }) => {
const pageNumbers = Array.from({ length: totalData?.last_page }, (_, index) => index + 1);

  return (
    <div className="flex items-center justify-between mt-10 border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{data?.length}</span> of{' '}
            <span className="font-medium">{totalData?.total}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            {totalData?.current_page > 1 ? (
                <a
                type='button'
                className="relative inline-flex items-center rounded-l-md px-2 cursor-pointer py-2 text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  <span>Previous</span>
                </a>
              ) : (
                <a
                  type='button'
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 cursor-not-allowed ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  <span>Previous</span>
                </a>
              )
            }
            {pageNumbers.map((pageNumber) => (
              <a
                key={pageNumber}
                href="#"
                onClick={() => onPageChange(pageNumber)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  pageNumber === totalData?.current_page
                    ? 'bg-green-700 text-white'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                } focus:z-20 focus:outline-offset-0`}
              >
                {pageNumber}
              </a>
            ))}
      
            {/* 
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span> */}
            {totalData?.current_page < totalData?.last_page ? (
              <a
                type='button'
                className="relative inline-flex items-center rounded-r-md px-2 py-2 cursor-pointer text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span>Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            ) : (
              <a
                type='button'
                className="relative inline-flex items-center rounded-r-md px-2 py-2 cursor-not-allowed text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span>Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            )
            }
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination

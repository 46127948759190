import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      // console.log("action", action)
      const existingIndex = state.cartItems.findIndex(
        (item) => item.data.id === action.payload.data.id,
      );
      // console.log("action.payload.data.id", action.payload.data.id)
      // console.log("existingIndex", existingIndex)

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
        };
        toast.info("Product Quantity has been Increased", {
          position: "bottom-right",
        });
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: action.payload.count };
        state.cartItems.push(tempProductItem);
        toast.success("Product has been added to the cart", {
          position: "bottom-right",
        });
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item?.data?.id === action.payload.id
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info("Product quantity has been decreased!", {
          position: "bottom-right",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item?.data?.id !== action.payload.id
        );

        state.cartItems = nextCartItems;

        toast.error("Product has been removed from cart!", {
          position: "bottom-right",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      
      const selectedIds = action.payload.ids;

      
      // const products = state.cartItems?.map(product => product?.data);
      
      // console.log("state.cartItems 11", state.cartItems)

      const nextCartItems = state.cartItems?.filter(item => !selectedIds.includes(item.data.id));
      // console.log("selectedIds23", nextCartItems)

      state.cartItems = nextCartItems;
          
      toast.error("Product has been removed from cart", {
        position: "bottom-right",
      });
      
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      return state;
    },


    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, discount_type, discount } = cartItem?.data;
          const { cartQuantity } = cartItem;

          let discountedPrice = parseFloat(price);
          let discountAmount = 0;
          if (discount) {
            discountAmount = discount_type === 'flat' ? parseFloat(discount) : discountedPrice * (parseFloat(discount) / 100);
          }
          discountedPrice -= discountAmount;
          const totalPriceForItem = discountedPrice * cartQuantity;

          // Accumulate the total and quantity
          cartTotal.quantity += cartQuantity;
          cartTotal.total += totalPriceForItem;
          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    
    clearCart(state, action) {
      state.cartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error("Cart has been cleared", { position: "bottom-right" });
    },
  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { app_url } from '../../features/api';

function CompanyDetails() {
  const info = useSelector((state) => state.siteInfo?.info);
  return (
    <div className='flex justify-between'>
      <div>
        <img className='min-w-24 h-12' src={`${app_url}/${info?.data?.logo}`} alt="logo" />
        <h1 className='font-semibold text-2xl text-gray-500'>Invoice</h1>
      </div>
      <div className='text-right'>
        <p>{info?.data?.application_name}</p>
        <p>{info?.data?.address}</p>
        <p>{info?.data?.email}</p>
        <p>{info?.data?.phone}</p>
      </div>
    </div>
  )
}

export default CompanyDetails

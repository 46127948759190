import React from 'react';

const CustomerWelcome = ({ ref, data }) => {
  return (
    <div ref={ref} className="py-4 border-b rounded mb-4">
      <h2 className="text-lg text-slate-700 font-semibold mb-1">Hello {data?.order?.name},</h2>
      <p>Your order has been confirmed and will be shipping within next 2-3 days</p>
    </div>
  );
};

export default CustomerWelcome;

import React from 'react';
import { app_url } from '../../features/api';

const OrderItems = ({ data }) => {
  let products = [];

  if (data?.order?.products) {
    try {
      products = JSON.parse(data.order.products);
    } catch (error) {
      console.error("Failed to parse products JSON:", error);
    }
  } else {
    console.warn("No products data available to parse.");
  }

  const calTax = (val, tax) => {
    const taxAmount = val * (tax / 100);
    const total = val + taxAmount;
    return total.toFixed(2)
  }

  return (
    <div className="py-4">
      <div className="flex justify-between py-2 border-b">
        <span className='w-1/3'>Item</span>
        <span>Tax Per Unit</span>
        <span>Unit Price</span>
        <span>Price <span className='text-slate-500 text-xs'>(* Quantity)</span></span>
      </div>
      {products?.map((item, i) => (
        <div key={i} className="flex justify-between py-2 border-b">
          <div className="flex items-center gap-2 w-1/3">
            <img className='rounded-md w-10' src={`${app_url}/storage/${item?.thumbnail}`} alt="" />
            <div>
              <p>{item?.name}</p>
              <p className='text-slate-500 text-sm'>Quantity: {item?.cartQuantity}</p>
              <ul className='flex gap-1'>
                {item?.attributes.map((attribute, attrIndex) => {
                  if (attribute?.name === 'colors') {
                    return (
                      <li className='text-sm' key={attrIndex}>
                        <span className='capitalize text-sm'>{attribute?.name}:</span> <span style={{ color: attribute?.value?.value }} >{attribute?.value?.title}</span>
                      </li>
                    );
                  }
                  return (
                    <li className='text-sm' key={attrIndex}>
                      <span className='capitalize'>{attribute?.name}:</span> {typeof attribute?.value === 'object' && attribute?.value !== null
                        ? `${attribute?.value?.title} (${attribute?.value?.value})`
                        : attribute?.value}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <span>{ item?.tax ? <>{item?.tax}%</> : '--'}</span>
          <span>{item.price.toFixed(2)}</span>
          {/* <span>{calTax((item?.cartQuantity * item?.price), item?.tax)}</span> */}
          <span>{(item?.cartQuantity * item?.price).toFixed(2)}</span>
        </div>
      ))}
    </div>
  );
};

export default OrderItems;

import axios from "axios";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { setNHeaders, url } from '../../../features/api';
import Paginate from "../../Shared/Pagination/Paginate";

const ProductsReviews = ({product, del, setDel}) => {
  const auth = useSelector((state) => state.auth);
  const user = auth?.token;

  const handleAvatar = (name) => {
    const words = name?.split(' ');
    const firstLetter = words[0][0]?.toUpperCase();
    const secondLetter = words[1] ? words[1][0]?.toUpperCase() : '';
    return firstLetter + secondLetter;
  }
  

  const [reviewVal, setReviewVal] = useState('');

  const [errors, setErrors] = useState({
    reviewVal: '',
  });

  const [totalRating, setTotalRating] = useState(5);
  const [rating, setRating] = useState(4);

  const validated = () => {
    const errors = {
      reviewVal: reviewVal ? '' : 'Comment is required.',
    };

    setErrors(errors);
    return Object.values(errors).every((error) => error === '');
  };


  const handleSubmitSecurity = (e) =>{
    e.preventDefault();
    const info = {
      rating : rating,
      review: reviewVal,
      product_id: product?.product_id
    }
    if (validated()) {
      // Make a POST request
      axios.post(`${url}/review/store`, info, setNHeaders())
      .then(response => {
        setReviewVal('')
        setDel(del+1)
        toast.success(response?.data?.message, {
          position: "top-right",
        });
      })
      .catch(err => {
        toast.error('Something went wrong!', {
          position: "bottom-right",
        });
      });
    } else {
      toast.error("Form contains errors!", {
        position: "bottom-right",
      });
    }

  }


  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const totalPages = Math.ceil(product?.reviews.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = product?.reviews.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <>
    {
      product?.review === 0 ? <h2 className="text-lg p-4 font-normal leading-7 text-red-700">Not Found :)</h2>
      : <>
      <div className="review">
      {
        currentItems?.map((review, i) => (
        <div key={i} class="grid w-full bg-gray-50 mt-2">
        <div class="px-4 flex items-center gap-4">
        <span className='w-12 h-12 flex items-center justify-center rounded-full border-2 border-[#006B2E] shadow-lg'>{handleAvatar(review?.user?.name == 'Admin' ? 'Unknown User' : review?.user?.name)}</span>        
        <div className="flex-1">
          <h6 class="block mt-4 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
            {review?.user?.name == 'Admin' ? 'Unknown User' : review?.user?.name} <span className="font-normal text-sm text-neutral-600">- {review?.created_at}</span>
          </h6>
          <p class="mb-1 flex">
            {Array.from({ length: totalRating }).map((_, index) => (
              <span key={index} onClick={() => setRating(index + 1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`w-5 h-5 cursor-pointer ${
                    index < review.rating ? 'text-yellow-500' : 'text-gray-400'
                  } hover:text-yellow-500`}
                >
                  <path
                    fillRule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            ))}
          </p>
          <p class="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
          {review?.review}
          </p>
          </div>
        </div>
      </div>
        ))
      }
      <Paginate
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

     </div>
     { 
      user &&
      <div className="bg-gray-50 shadow-md px-4 py-4 rounded mt-4">
        <form onSubmit={handleSubmitSecurity}>
          <div className="space-y-12">
            <div className="pb-2">
              <h2 className="text-xl font-normal leading-7 text-gray-700">Add a Review</h2>
              <div className="mt-4">
                <div class="">
                  <div class="flex">
                  Your Rating: 
                  {Array.from({ length: totalRating }).map((_, index) => (
                    <span key={index} onClick={() => setRating(index + 1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className={`w-6 h-6 cursor-pointer ${
                          index < rating ? 'text-yellow-500' : 'text-gray-400'
                        } hover:text-yellow-500`}
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  ))}
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <textarea 
                id="review" 
                name="review"
                value={reviewVal}
                placeholder='Type your comment ...'
                onChange={(e) => setReviewVal(e.target.value)}
                className={`${ errors?.reviewVal ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md p-2`}
                rows={3}
                >
                </textarea>
                <span className="text-sm text-red-600">{errors.reviewVal}</span>
              </div>
            </div>
          </div>
          <div className="mt-2 flex gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[rgb(61,46,60)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[rgb(73_53_72)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      }
      </>
    }
    </>
  )
}

export default ProductsReviews;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {
    const location = useLocation();
    const { children } = props;

    useEffect(() => {
        window.scrollTo(0, 0, 'smooth');
    }, [location]);

    return <>{children}</>;
};

export default ScrollToTop;

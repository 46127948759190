import axios from "axios";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { setNHeaders, url } from "../../features/api";
import BillingInformation from "./BillingInformation";
import CompanyDetails from "./CompanyDetails";
import CustomerWelcome from "./CustomerWelcome";
import OrderItems from "./OrderItems";
import OrderSummary from "./OrderSummary";

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const pathParts = location.pathname.split('/');
  const orderId = pathParts[2];

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   if (auth.id) {
  //     if(!auth.phone_verified_at.length > 0){
  //       navigate("/verify-phone");
  //     }
  //   }
  // }, [auth.id, navigate]);

  useEffect(() => {
    if (auth?.id) {
      if(!auth?.phone_verified_at?.length > 4){
        navigate("/verify-phone");
      }
    }else{
      navigate("/login");
    }
  }, [auth?.id, auth?.status, auth?.verifyStatus, auth?.phone_verified_at, navigate]);

  useEffect(() => {
    axios.get(`${url}/my-order/${orderId}`, setNHeaders())
      .then(response => {
        setData(response?.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let sslcommerzScript;
    if (data?.order?.orderid) {
      const sslcommerzScript = document.createElement('script');
      sslcommerzScript.src =
        'https://sandbox.sslcommerz.com/embed.min.js?' +
        Math.random().toString(36).substring(7);
      sslcommerzScript.async = true;

      // Append the script to the document
      document.body.appendChild(sslcommerzScript);
    }

    return () => {
      // @ts-ignore
      if(sslcommerzScript){
        document.body.removeChild(sslcommerzScript);
      }
    };
  }, [data?.order?.orderid]);

  // console.log("data20", data?.order?.orderid)
  // console.log("env", process.env.NEXT_PUBLIC_ADMIN_URL)


  return (
    <section className="my-6 px-4 md:px-10 2xl:px-20 min-h-screen">
      <div className="sm:container mx-auto">
        <div className="flex justify-between max-w-4xl mx-auto py-4">
          <h2 className="font-medium text-lg">Oder Details</h2>

          {
          data?.order?.payment === "unpaid" ?
          <>
            {
              data?.order?.orderid && 
              <button
                id="sslczPayBtn"
                token="if you have any token validation"
                postdata=""
                order={data?.order?.orderid}
                endpoint={'https://admin.nikhad.com/pay-via-ajax'}
              >
                Pay Now
              </button>
            }
            </> : ''
          }

          <Link to='/customer/orders' className="px-4 py-1 h-fit bg-red-600 text-white rounded-xl">Back</Link>
        </div>

        {/* body */}
        <div ref={componentRef} className="max-w-4xl mx-auto p-4 px-6 bg-slate-50">
        <CompanyDetails />
        <CustomerWelcome data={data} />
        <OrderSummary data={data} />
        <OrderItems data={data} />
        <BillingInformation data={data} />
        </div>
        <div ref={componentRef} className="max-w-4xl flex justify-end mx-auto p-4 px-6">
        {/* <OrderActions handlePrint={handlePrint} /> */}
        </div>
      </div>
    </section>
  )
}

export default OrderDetails

import React from 'react';

const OrderSummary = ({ data }) => {

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return null;
    }
    return date.toISOString().split('T')[0];
  }

  return (
    <div className="mb-2 py-2 border-b rounded flex justify-between">
      <div>
        <h2 className="text-base text-slate-500 font-light mb-2">Order ID</h2>
        <p className='text-sm text-gray-600 font-medium mb-2'>#{data?.order?.orderid}</p>
      </div>
      <div>
        <h2 className="text-base text-slate-500 font-light mb-2">Order Date</h2>
        <p className='text-normal text-gray-600 font-medium mb-2'>{formatDate(data?.order?.created_at)}</p>
      </div>
      <div>
        <h2 className="text-base text-slate-500 font-light mb-2">Payment</h2>
        <p className='text-normal text-gray-600 font-medium mb-2 capitalize'>{data?.order?.payment_method}</p>
      </div>
      <div>
        <h2 className="text-base text-slate-500 font-light mb-2">Shipping Address</h2>
        <p className='text-normal text-gray-600 font-medium mb-2'>{data?.order?.address}</p>
      </div>
    </div>
  );
};

export default OrderSummary;

import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import App from './App';
import './index.css';

import authReducer, { loadUser } from './features/authSlice';
import cartReducer, { getTotals } from './features/cartSlice';
import categoryReducer, { categoryFetch } from './features/categorySlice';
import favoriteReducer from './features/favoriteSlice';
import productsReducer from './features/productsSlice';
import { salesProducts } from './features/salesProducts';
import siteInfoReducer, { fetchInfo } from './features/siteInfoSlice';
import topSliderReducer from './features/topSliderSlice';

export const store = configureStore({
  reducer: {
    siteInfo: siteInfoReducer,
    category: categoryReducer,
    topSlider: topSliderReducer,
    products: productsReducer,
    cart: cartReducer,
    favorite: favoriteReducer,
    auth: authReducer,
    [salesProducts.reducerPath]: salesProducts.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(salesProducts.middleware),
});

store.dispatch(fetchInfo());
store.dispatch(categoryFetch());
store.dispatch(getTotals());
store.dispatch(loadUser(null));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
    </React.StrictMode>
);


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountProducts } from '../../../features/productsSlice';
import NewArrivals from '../../Shared/NewArrivals/NewArrivals';
import OnSale from '../../Shared/OnSale/OnSale';
import Banner from '../Banner/Banner';
import TopBanner from '../TopBanner/TopBanner';
import TopProduct from '../TopProduct/TopProduct';

const Home = () => {
  const products = useSelector((state) => state.products);
  const sellingProducts = products?.discountProducts?.data?.products?.data?.slice(0, 10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDiscountProducts());
  }, [dispatch])


  return  <>
        <TopBanner />
        {/* <FlashSale /> */}
        <TopProduct sellingProducts={sellingProducts} />
        <OnSale />
        <Banner />
        <NewArrivals />
    </>
};

export default Home;

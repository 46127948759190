import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const salesProducts = createApi({
  reducerPath: "salesProducts",
  baseQuery: fetchBaseQuery({ baseUrl: "https://dummyjson.com/" }),
  endpoints: (builder) => ({
    getAllSalesProducts: builder.query({
      query: () => "products",
    }),
    
    getProduct: builder.query({
      query: (product) => `products/search?q=${product}`,
    }),
  }),
});

export const { useGetAllSalesProductsQuery, useGetProductQuery } = salesProducts

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./api";

const initialState = {
  categories: {},
  status: null,
};

export const categoryFetch = createAsyncThunk(
  "category/categoryFetch",
  async() => {
      const response = await axios.get(`${url}/categories`)
      return response?.data
  }
)

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: {
    [categoryFetch.pending]: (state, action) =>{
      state.status = "pending"
    },
    [categoryFetch.fulfilled]: (state, action) =>{
      state.status = "success";
      state.categories = action.payload;
    },
    [categoryFetch.rejected]: (state, action) =>{
      state.status = "rejected";
    },
  }
});

export default categorySlice.reducer;

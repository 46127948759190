import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  favoriteItems: localStorage.getItem("favoriteItems")
    ? JSON.parse(localStorage.getItem("favoriteItems"))
    : [],
};

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    addToFavorite(state, action) {
      const existingIndex = state.favoriteItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.favoriteItems.map((favoriteItem) => {
          if (favoriteItem.id === action.payload.id) {
            const nextFavoriteItems = state.favoriteItems.filter(
              (item) => item.id !== favoriteItem.id
            );
            state.favoriteItems = nextFavoriteItems;
          }
        });
        toast.info("Product has been removed from Favorite!", {
          position: "bottom-right",
        });
      } else {
        let tempProductItem = { ...action.payload, favoriteQuantity: 1 };
        state.favoriteItems.push(tempProductItem);
        toast.success("Product has been added to the favorite!", {
          position: "bottom-right",
        });
      }
      localStorage.setItem("favoriteItems", JSON.stringify(state.favoriteItems));
      return state;
    },
  },
});

export const { addToFavorite, getFavoriteTotals } =
favoriteSlice.actions;

export default favoriteSlice.reducer;

import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNHeaders, url } from '../../features/api';
import CheckOutCard from './CheckOutCard';
import DirectOrder from './DirectOrder';
import ShippingDetails from './ShippingDetails';

const CheckOut = () => {
  const auth = useSelector((state) => state.auth);
  const cartData = useSelector((state) => state.cart);
  const [data, setData] = useState(null);
  const [aData, setAData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dLoading, setDLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const navigate = useNavigate();
  const [add, setAdd] = useState(1);
  const [mobile, setMobile] = useState('');

  const [coupon, setCoupon] = useState("");

  const [process, setProcess] = useState({
    sAddress: null,
    dAddress: "",
    payment: "",
  });

  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    address: ''
  });

  // console.log("process", process)
  // console.log("paymentMethod", paymentMethod)
  // console.log('formData', formData)

  useEffect(() => {
    if (cartData?.cartItems?.length === 0) {
      navigate("/cart");
    }
    else{
      navigate("/checkout");
    }
  }, []);


  // first request shipping address
  useEffect(() => {
     axios.get(`${url}/shippings-data`, setNHeaders())
      .then(response => {
        setData(response?.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Second request for delivery address
  useEffect(() => {
    axios.get(`${url}/address-data`, setNHeaders())
      .then(response => {
        setAData(response?.data?.data?.profile)
      })
      .catch(secondError => {
        setError(secondError);
      })
      .finally(() => {
        setDLoading(false);
      });
  }, [add]);

  // third request for payment methods
  useEffect(() => {
    axios.get(`${url}/payment-gateway-data`, setNHeaders())
      .then(response => {
        setPaymentMethod(response?.data?.res)
      })
      .catch(secondError => {
        setError(secondError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

    return (
        <section className="my-10 px-4 md:px-10 2xl:px-20 min-h-screen">
            <div className="sm:container mx-auto">
                <div className="flex flex-col lg:flex-row gap-10">
                  {
                    auth?.id ? 
                    (
                      auth.phone_verified_at?.length > 4 ?
                      <ShippingDetails loading={loading} data={data} dLoading={dLoading} aData={aData} paymentMethod={paymentMethod} process={process} setProcess={setProcess} add={add} setAdd={setAdd} />
                      :
                      (
                        <div className="w-full xl:w-2/3 flex align-middle justify-center items-center">
                          <a href="/verify-phone" className='text-xl hover:text-green-700 underline'>Verify Your Phone Before Place Order</a>
                        </div>
                      )
                    ) : (
                      <DirectOrder data={data} setMobile={setMobile} mobile={mobile} paymentMethod={paymentMethod} process={process} setProcess={setProcess} formData={formData} setFormData={setFormData} />
                    )
                  }

                  {/* oder card  */}
                  {
                    auth?.id ?  <CheckOutCard process={process} formData={formData} mobile={mobile} /> :  <CheckOutCard process={process} formData={formData} mobile={mobile} />
                  }
                </div>
            </div>
        </section>
    );
};

export default CheckOut;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { url } from "./api";

const initialState = {
  token: localStorage.getItem("token"),
  name: localStorage.getItem("name"),
  phone: localStorage.getItem("phone"),
  id: localStorage.getItem("id"),
  status: localStorage.getItem("status"),
  phone_verified_at: localStorage.getItem("phone_verified_at"),
  registerStatus: "",
  registerError: "",
  loginStatus: "",
  loginError: "",
  otpStatus: "",
  otpError: "",
  changePassStatus: "",
  changePassError: "",
  updatePassStatus: "",
  updatePassError: "",
  verifyStatus: "",
  verifyError: "",
  verityAccountNo: "",
  userLoaded: false,
};

// registerUser
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (user, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${url}/register`, {
        name: user.name,
        phone: user.phone,
      });
      localStorage.setItem("token", data.data.data.token);
      localStorage.setItem("name", data.data.data.name);
      localStorage.setItem("phone", data.data.data.phone);
      localStorage.setItem("id", data.data.data.id);
      localStorage.setItem("status", data.data.data.status);
      localStorage.setItem("phone_verified_at", data.data.data.phone_verified_at);
      toast.success("User has been registered successfully!", {
        position: "top-right",
      });
      return data.data.data;
    } catch (error) {
      if (error?.response?.data?.success == false) {
        if(error?.response?.data?.message?.name){
          toast.error(error?.response?.data?.message?.name[0], {
            position: "top-right",
          });
        }else if(error?.response?.data?.message?.phone){
          toast.error(error?.response?.data?.message?.phone[0], {
            position: "top-right",
          });
        }
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// loginUser
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (user, { rejectWithValue }) => {
    try {
      const data = await axios.post(`${url}/login`, {
        phone: user?.phone,
      });

      if (data?.data?.success === false) {
        toast.error("Wrong User Phone or Password", {
          position: "top-right",
        });
      }

      localStorage.setItem("token", data?.data?.data?.token);
      localStorage.setItem("name", data?.data?.data?.name);
      localStorage.setItem("phone", data?.data?.data?.phone);
      localStorage.setItem("id", data?.data?.data?.id);
      localStorage.setItem("status", data?.data?.data?.status);
      localStorage.setItem("phone_verified_at", data?.data?.data?.phone_verified_at);

      toast.success("Welcome Back!", {
        position: "top-right",
      });
      return data?.data?.data;
    } catch (error) {
      if (error?.response?.data?.success == false) {
        if(error?.response?.data?.message?.phone){
          toast.error(error?.response?.data?.message?.phone[0], {
            position: "top-right",
          });
        }
        if(error?.response?.data?.message?.password){
          toast.error(error?.response?.data?.message?.password[0], {
            position: "top-right",
          });
        }
      }
      // console.log(error.response)
      return rejectWithValue(error?.response?.data);
    }
  }
);

// sendResetOtp
export const sendResetOtp = createAsyncThunk(
  "auth/sendResetOtp",
  async (number, { rejectWithValue }) => {
    try {
      const result = await axios.post(`${url}/reset/send-reset-otp`, {phone: number});

      if (result?.data?.success === false) {
        toast.error(result?.data?.message, {
          position: "top-right",
        });
      }
      
      if (result?.data?.success === true) {
        toast.success("OTP code has been sent!", {
          position: "top-right",
        });
      }
      
      return result?.data?.data;
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
        });
      }
      console.log('error', error?.response)
      return rejectWithValue(error?.response?.data);
    }
  }
);

// verifyOtp
export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (otp, { rejectWithValue }) => {
    try {
      const result = await axios.post(`${url}/reset/otp-verify`, {
        phone: otp.phone,
        otp_code: otp.otp_code,
      });
            
      if (result?.data?.success == false) {
        toast.error(result?.data?.message, {
          position: "top-right",
        });
      }
      
      if (result?.data?.success == true) {
        toast.info("Now you can change password!", {
          position: "bottom-right",
        });
      }
      return result?.data;
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
        });
      }
      console.log('error', error.response)
      return rejectWithValue(error.response.data);
    }
  }
);

// verifyPhone
export const verifyPhone = createAsyncThunk(
  "auth/verifyPhone",
  async (otp, { rejectWithValue }) => {
    try {
      const result = await axios.post(`${url}/otp/verify-phone`, {
        phone: otp.phone,
        otp_code: otp.otp_code,
      });
      
      if (result?.data?.success == false) {
        toast.error(result?.data?.message, {
          position: "top-right",
        });
      }
      
      if (result?.data?.success == true) {
        toast.success(result?.data?.info?.message, {
          position: "bottom-right",
        });
      }
      localStorage.setItem("status", result?.data?.info.status);
      localStorage.setItem("phone_verified_at", result?.data?.info.phone_verified_at);
      return result?.data?.info;
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
        });
      }
      console.log('error', error.response)
      return rejectWithValue(error.response.data);
    }
  }
);

// changePassword
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(`${url}/reset/reset-password`, {
        phone: data.phone,
        password: data.password,
        confirm_password: data.confirm_password,
      });
      
      if (result?.data?.success == false) {
        toast.error(result?.data?.message, {
          position: "top-right",
        });
      }
      
      if (result?.data?.success == true) {
        toast.success(result?.data?.message, {
          position: "top-right",
        });
      }
      return result?.data;
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
        });
      }
      console.log('error', error.response)
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadUser(state, action) {
      const token = state.token;
      if (token) {
        return {
          ...state,
          token,
          name: token > 10 ? state.name : null,
          phone: token > 10 ? state.phone : null,
          id: token > 10 ? state.id : null,
          status: token > 10 ? state.status : null,
          phone_verified_at: token > 10 ? state.phone_verified_at : null,
          userLoaded: true,
        };
      } else return { ...state, userLoaded: true };
    },
    logoutUser(state, action) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("phone");
      localStorage.removeItem("id");
      localStorage.removeItem("status");
      localStorage.removeItem("phone_verified_at");
      localStorage.removeItem("cartItems");
      localStorage.removeItem("favoriteItems");
      localStorage.removeItem("verityAccountNo");
      return {
        ...state,
        user: null,
        token: "",
        name: "",
        phone: "",
        id: "",
        status: "",
        phone_verified_at: "null",
        registerStatus: "",
        registerError: "",
        loginStatus: "",
        loginError: "",
      };
    },
  },
  extraReducers: (builder) => {
    // register extraReducers
    builder.addCase(registerUser.pending, (state, action) => {
      return { ...state, registerStatus: "pending" };
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload.token,
          name: action.payload.name,
          phone: action.payload.phone,
          id: action.payload.id,
          status: action.payload.status,
          phone_verified_at: action.payload.phone_verified_at,
          registerStatus: "success",
        };
      } else return state;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      return {
        ...state,
        registerStatus: "rejected",
        registerError: action.payload,
      };
    });

    //login extraReducers
    builder.addCase(loginUser.pending, (state, action) => {
      return { ...state, loginStatus: "pending" };
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          token: action.payload.token,
          name: action.payload.name,
          phone: action.payload.phone,
          id: action.payload.id,
          status: action.payload.status,
          phone_verified_at: action.payload.phone_verified_at,
          loginStatus: "success",
        };
      } else return state;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      return {
        ...state,
        loginStatus: "rejected",
        loginError: action.payload,
      };
    });

    //sendResetOtp extraReducers
    builder.addCase(sendResetOtp.pending, (state, action) => {
      return { ...state, otpStatus: "pending" };
    });
    builder.addCase(sendResetOtp.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          otpStatus: "success",
          verityAccountNo: action.payload.number
        };
      } else return state;
    });
    builder.addCase(sendResetOtp.rejected, (state, action) => {
      return {
        ...state,
        otpStatus: "rejected",
        otpError: action.payload,
      };
    });

    //verifyOtp extraReducers
    builder.addCase(verifyOtp.pending, (state, action) => {
      return { ...state, changePassStatus: "pending" };
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          changePassStatus: "success",
        };
      } else return state;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      return {
        ...state,
        changePassStatus: "rejected",
        changePassError: action.payload,
      };
    });

    //changePassword extraReducers
    builder.addCase(changePassword.pending, (state, action) => {
      return { ...state, updatePassStatus: "pending" };
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          updatePassStatus: "success",
        };
      } else return state;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      return {
        ...state,
        updatePassStatus: "rejected",
        updatePassError: action.payload,
      };
    });

    //verifyPhone extraReducers
    builder.addCase(verifyPhone.pending, (state, action) => {
      return { ...state, verifyStatus: "pending" };
    });
    builder.addCase(verifyPhone.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          status: action.payload.status,
          phone_verified_at: action.payload.phone_verified_at,
          verifyStatus: "success",
        };
      } else return state;
    });
    builder.addCase(verifyPhone.rejected, (state, action) => {
      return {
        ...state,
        verifyStatus: "rejected",
        verifyError: action.payload,
      };
    });
  },
});

export const { loadUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;



import React, { useEffect, useRef, useState } from 'react';

export default function CountDown({seconds, handleSubmit}) {
  const [countDown, setCountDown] = useState(seconds);
  const timerId = useRef()

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountDown(prev => prev - 1)
    }, 1000);
    return () => clearInterval(timerId.current)
  }, [])

  useEffect(() => {
    if(countDown <= 0){
      clearInterval(timerId.current)
    }
  }, [countDown])
  
  return (
    <>
    {
      countDown > 1 ? 
      <span className='text-red-600 text-sm'>OTP will be expired: <b>{countDown}</b></span>
      : <button onClick={handleSubmit} className="text-gray-600 hover:underline hover:font-semibold text-sm block mt-1">Resend OTP</button>
    }
    </>

  )
}

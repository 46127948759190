import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
  TEModalFooter,
  TEModalHeader,
  TERipple,
} from "tw-elements-react";
import { loginUser, sendResetOtp, verifyPhone } from '../../features/authSlice';
import CountDown from '../CountDown';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [countTime, setCountTime] = useState(false);
  const [otp, setOtp] = useState({
    phone: auth?.phone,
    otp_code: "",
  });


  const initialTime = 60; // 1 minutes in seconds
  const [seconds, setSeconds] = useState(initialTime);

  const [user, setUser] = useState({
    phone: "",
  });

  const [errors, setErrors] = useState({
    phone: '',
  });
  const [errorsOTP, setErrorsOTP] = useState('');

  useEffect(() => {
    if (auth?.id) {
      if (auth?.status) {
        if (auth?.phone_verified_at?.length > 5) {
          navigate("/customer/profile");
        }
        else {
          setOtp({...otp, phone: auth?.phone})
          navigate("/login");
        }
      }
    }
    else{
      navigate("/login");
    }
  }, [auth?.id, auth?.status, auth?.verifyStatus, auth?.phone_verified_at, navigate]);


  const validateForm = () => {
    const newErrors = {
      phone: user?.phone ? '' : 'Phone number is required.',
    };

    setErrorsOTP(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(loginUser(user));
      setShowModal(true);
      setCountTime(true)
    } else {
      toast.error("Please enter valid phone number", {
        position: "top-right",
      });
    }
  };

  const validateOtpForm = () => {
    const newErrors = {
        otp: otp.otp_code ? '' : 'OTP code is required.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmitOTP = (e) => {
    e.preventDefault();
    if (validateOtpForm()) {
      dispatch(verifyPhone(otp));
    } else {
      console.log('Form contains errors. Please check OTP!')
    }
  }

  const handleResend = (e) =>{
    e.preventDefault();
    if (auth?.phone) {
      setCountTime(true)
      setSeconds(initialTime)
      dispatch(sendResetOtp(auth?.phone));
    } else {
      console.log('Form contains errors!')
    }
  }


    return (
      <>
        <section className="md:px-10 2xl:px-20">
          <form onSubmit={handleSubmit}>
          <div className="min-h-screen flex align-middle items-center justify-center">
            <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
              <h3 className="font-semibold text-4xl text-[#006B2E] mb-2">Login</h3>
              <p className="mb-4 text-slate-600">Welcome Back!</p>

              <div className="w-full gap-5 mt-5">
                <label className='text-slate-600' htmlFor="note">Phone *</label>
                <input
                  type="text"
                  name="zip"
                  placeholder='Type your 11 digit valid phone number'
                  onChange={(e) => setUser({ ...user, phone: e.target.value})}
                  className={`${ errors?.phone ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errors.phone}</span>
              </div>
                
                
              <div className="grid mt-5">
                  <button
                      className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                      type="submit"
                  >
                      Login
                  </button>
                  <div className="py-5 flex justify-between items-center gap-2">
                    <a href='/register' className="text-gray-600 hover:underline text-sm">Don't have an account? <span className='font-semibold underline'>Register</span></a>
                  </div>
              </div>
            </div>
          </div>
          </form>
        </section>

        {/* <!-- otp verify Modal --> */}
      <TEModal show={showModal} setShow={setShowModal}>
          <TEModalDialog>
            <TEModalContent>
              <form onSubmit={handleSubmitOTP}>
              <TEModalHeader>
                {/* <!--Modal title--> */}
                <h5 className="text-xl font-medium leading-normal text-slate-500">
                  Verify Phone Number
                </h5>
                {/* <!--Close button--> */}
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </TEModalHeader>
              {/* <!--Modal body--> */}
              <TEModalBody>
                <div className="w-full gap-5 mt-5">
                <label className='text-slate-600' htmlFor="note">Type OTP *</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder='OTP'
                    onChange={(e) => setOtp({...otp, otp_code: e.target.value})}
                    className={`${ errors?.otp ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <p className="text-sm text-red-600 mb-0">{errorsOTP.otp}</p>
                </div>

                <div className="py-5 flex justify-between items-center gap-2">
                {
                  countTime ? (
                    <CountDown seconds={seconds} handleSubmit={handleResend} />
                  ) : ''
                }
                </div>
                
              </TEModalBody>
              <TEModalFooter>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-green-700 transition duration-150 ease-in-out hover:bg-green-accent-100 focus:bg-green-accent-100 focus:outline-none focus:ring-0 active:bg-green-accent-200"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="submit"
                    className="ml-1 inline-block rounded bg-green-700 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[rgb(61,46,60)]"
                  >
                    Submit
                  </button>
                </TERipple>
              </TEModalFooter>
              </form>
            </TEModalContent>
          </TEModalDialog>
      </TEModal>
      </>
    );
};

export default Login;

export const url = "https://admin.nikhad.com/api/v1";
export const app_url = "https://admin.nikhad.com";

// export const url = "http://127.0.0.1:8000/api/v1";
// export const app_url = "http://127.0.0.1:8000";

export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      "X-CSRF-TOKEN": ('meta[name="csrf-token"]').attr('content')
    },
  };

  return headers;
};
 
export const setNHeaders = () => {
  const NHeaders = {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return NHeaders;
};
 
import React from 'react';

function SkeletonCartItem() {
  return (
    <>
    <div
      className="mt-4 px-2 md:px-5 py-3 flex flex-wrap gap-5 justify-between shadow-[0px_3px_3px_0_rgb(0,0,0,6%)] hover:shadow-[rgba(0,0,0,0.24)_0px_3px_8px] rounded-md"
    >
      <div className="flex flex-wrap gap-4">
          <div className="flex justify-center items-center">
            <div className="h-5 w-5 font-normal text-md bg-slate-500 rounded"></div>
          </div>
          <div className="w-24">
            {/* img  */}
            <div className="w-24 h-24 rounded-md bg-slate-400"></div>
          </div>
          <div className="flex flex-col justify-between">
              <div>
                {/* title */}
                <div className="h-3 w-64 mb-3 font-normal text-md bg-slate-500 rounded"></div>
                <div className="h-2.5 w-40 mb-3 font-normal text-md bg-slate-400 rounded"></div>
                <div className="h-2 w-36 mb-1 font-normal text-md bg-slate-400 rounded"></div>
              </div>
              <div className='flex items-center'>
                <svg className="w-5 h-5 text-slate-400 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <p className="h-2 w-28 font-normal text-md bg-slate-400 rounded"></p>
              </div>
          </div>
      </div>
      <div className="flex flex-wrap gap-5 flex-row md:flex-col justify-between">
          <div className="flex xl:justify-end">
              <small className="h-2 w-24 mb-1 font-normal text-md bg-slate-400 rounded"></small>
          </div>
          <div className="flex items-center gap-6">
              <div className="flex items-center w-40 h-10 border border-[rgb(216_216_216)] bg-slate-400 gap-5 md:gap-10 rounded-md py-2 px-3">
              </div>
              <div>
                  <p className="font-semibold w-24 h-3 rounded bg-slate-500 text-gray-500 text-sm"></p>
              </div>
          </div>
      </div>
  </div>
    </>
  )
}

export default SkeletonCartItem

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React from 'react';
import { useSelector } from 'react-redux';

const SideBar = ({location}) => {
  const auth = useSelector((state) => state.auth);
  const userName = auth?.name || '';  // Fallback to an empty string if auth.name is undefined
  const words = userName ? userName.split(' ') : [];  // Ensure words is an array
  const firstLetter = words?.length > 0 && words[0] ? words[0][0]?.toUpperCase() : '';
  const secondLetter = words?.length > 1 && words[1] ? words[1][0]?.toUpperCase() : '';
  const avatar = firstLetter + secondLetter;


  return (
    <>
    <div className="flex justify-between gap-x-6 py-5 border-b border-slate-200">
      <div className="flex min-w-0 gap-x-4">
      <span className='w-12 h-12 flex items-center justify-center rounded-full border-2 border-[#006B2E] shadow-lg'>{avatar}</span>
      <div className="min-w-0 flex-auto">
          <p className="text-lg font-semibold leading-6 text-slate-700 capitalize">{userName}</p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{auth?.phone}</p>
        </div>
      </div>
    </div>
    <ul role="list" className="divide-y divide-gray-100">
      <li className="flex justify-between gap-x-6 py-3">
        <a href='/customer/profile' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/profile' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 shadow-md text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
          <AccountCircleIcon style={{ fontSize: '30px' }} />
          <div className="min-w-0 flex-auto">
            <p className="text-lg font-normal leading-6">My Profile</p>
          </div>
        </a>
      </li>
      <li className="flex justify-between gap-x-6 py-3">
        <a href='/customer/orders' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/orders' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 shadow-md text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
          <ShoppingCartIcon style={{ fontSize: '30px' }} />
          <div className="min-w-0 flex-auto">
            <p className="text-lg font-normal leading-6">My Orders</p>
          </div>
        </a>
      </li>
      <li className="flex justify-between gap-x-6 py-3">
        <a href='/customer/settings' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/settings' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 shadow-md text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
          <SettingsIcon style={{ fontSize: '30px' }} />
          <div className="min-w-0 flex-auto">
            <p className="text-lg font-normal leading-6">Settings</p>
          </div>
        </a>
      </li>
    </ul>
    </>
  )
}

export default SideBar

import React from 'react';

const BillingInformation = ({ data }) => {
  const calTotal = (amount, shipping, tax) =>{
    const nAmount = parseFloat(amount);
    const nShipping = parseFloat(shipping);
    const nTax = parseFloat(tax);
    return nAmount + nShipping + nTax;
  }
  const tax = (tax) =>{
    return parseFloat(tax).toFixed(2);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return null;
    }
    return date.toISOString().split('T')[0];
  }

  return (
    <div className="mb-4 flex justify-between items-center">
      <div className="">
        <h3 className="text-sm text-slate-600 mb-2">Order Status: 
        {
          data?.order?.status?.code === 1 ? (
            <>
            <span className='text-gray-600 px-3 rounded-xl font-semibold'>Pending</span>
            </>
          ) :
          data?.order?.status?.code === 2 ? (
            <span className='text-teal-700 px-3 rounded-xl font-semibold'>Processing</span>
          ) :
          data?.order?.status?.code === 3 ? (
            <span className='text-blue-600 px-3 rounded-xl font-semibold'>Confirmed</span>
          ) :
          data?.order?.status?.code === 6 ? (
            <span className='text-red-600 px-3 rounded-xl font-semibold'>Canceled</span>
          ) :
          data?.order?.status?.code === 4 ? (
            <>
            <p className='text-md font-semibold mt-0.5 text-slate-500'>Delivered on {formatDate(data?.order?.updated_at)}</p>
            </>
          ) : ''
        }
        </h3>
        <h3 className="text-sm text-slate-600">Payment Status: 
        {
          data?.order?.payment === "paid" ? (
            <>
            <span className='text-green-700 px-3 rounded-xl font-semibold capitalize'>{data?.order?.payment}</span>
            </>
          ) :
          data?.order?.payment === "unpaid" ? (
            <span className='text-blue-600 px-3 rounded-xl font-semibold capitalize'>{data?.order?.payment}</span>
          ) : ''
        }
        </h3>
      </div>
      <div className="w-72">
        <div className="flex justify-between pb-2">
          <p className='text-slate-500 font-light text-sm'>Subtotal</p>
          <p className='text-slate-900'>{data?.order?.subtotal} tk</p>
        </div>
        <div className="flex justify-between pb-2">
          <p className='text-slate-500 font-light text-sm'>Shipping Fee</p>
          <p className='text-slate-900'>{data?.order?.shipping_cost} tk</p>
        </div>
        <div className="flex justify-between border-b pb-2">
          <p className='text-slate-500 font-light text-sm'>Tax Fee</p>
          <p className='text-slate-900'>{tax(data?.order?.tax)} tk</p>
        </div>
        <div className="flex justify-between mt-2">
          <p className='text-slate-900 font-medium'>Total</p>
          <p className='text-slate-900 font-medium'>{calTotal(data?.order?.subtotal, data?.order?.shipping_cost, data?.order?.tax).toFixed(2)}  TK</p>
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { app_url, setNHeaders, url } from '../../../features/api';

const OrderHistory = () => {
  const [order, setOrder] = useState(true)
  const [status, setStatus] = useState('shipped')

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [del, setDel] = useState(1);

  useEffect(() => {
    // Make a GET request using Axios
     axios.get(`${url}/my-orders`, setNHeaders())
      .then(response => {
        setData(response?.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [del]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return null;
    }
    return date.toISOString().split('T')[0];
  }

  function productsParse(productsJson) {
    return  JSON.parse(productsJson);
  }

  const calTotal = (price, tax) =>{
    const nPrice = parseFloat(price);
    const nTax = parseFloat(tax);
    return nPrice + nTax;
  }


  return (
    <>
    <div className="">
      <h2 className="text-xl font-normal leading-7 text-green-700">Order History</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Total {data?.orders?.length < 2 ? 'order' : 'orders'} {data?.orders?.length}.
      </p>
      
      {
        data?.orders?.length <= 0 ? (
          <div className="w-full block text-center py-16">
              <h3 className="text-red-700 mb-2">No order Found</h3>
              <a href="/" className="px-2 py-1 bg-green-700 text-slate-100 rounded">Back to Shopping</a>
          </div>
        ) : (
          <>
          {
            loading ? 
            <div className="w-full block py-6 border border-gray-300 rounded mt-8">
              {/* order header  */}
              <div className="flex justify-between items-center px-4 border-b border-gray-300 pb-6">
                <div className="flex-1 flex gap-8">
                  <div className='text-center'>
                    <h4 className="bg-slate-700 h-5 w-20 mb-2 rounded-sm"></h4>
                    <p className="bg-slate-500 h-4 w-12 rounded-sm"></p>
                  </div>
                  <div className='text-center'>
                    <h4 className="bg-slate-700 h-5 w-40 mb-2 rounded-sm"></h4>
                    <p className="bg-slate-500 h-4 w-16 rounded-sm"></p>
                  </div>
                  <div className='text-center'>
                    <h4 className="bg-slate-700 h-5 w-24 mb-2 rounded-sm"></h4>
                    <p className="bg-slate-500 h-4 w-10 rounded-sm"></p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="w-24 h-8 border border-slate-400 bg-slate-500 rounded-md px-3 py-1.5"></div>
                  <div className="w-24 h-8 border border-slate-400 bg-slate-500 rounded-md px-3 py-1.5"></div>
                </div>
              </div>

              {/* order body  */}
              <div>
                <div className="flex justify-between items-center px-4 py-4">
                  <div className="flex-1">
                    <div className="flex justify-between items-center">
                      <h2 className="bg-slate-600 h-5 w-40 mb-2 rounded-sm"></h2>
                      <h2 className="bg-slate-700 h-5 w-12 mb-2 rounded-sm"></h2>
                    </div>
                    <div className='mt-2'>
                      <p className='bg-slate-400 h-4 w-full mb-2 rounded-sm'></p>
                      <p className='bg-slate-400 h-4 w-full mb-2 rounded-sm'></p>
                      <p className='bg-slate-400 h-4 w-full mb-2 rounded-sm'></p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center px-4">
                  <div className='flex items-center gap-1'>
                  <p className='bg-slate-600 h-5 w-28 rounded-sm'></p>
                  </div>
                  <div className='bg-green-600 h-5 w-20 rounded-sm'></div>
                </div>
              </div>
            </div> :
            <>
            {
              data?.orders?.map((item, index) => (
                <div key={index} className="w-full block pt-6 border border-gray-300 rounded mt-8">
                  {/* order header  */}
                  <div className="flex justify-between items-center px-4 border-b border-gray-300 pb-6">
                    <div className="flex-1 flex gap-8">
                      <div className='text-center'>
                        <h4 className="text-slate-700 text-sm mb-2 font-semibold">Order ID</h4>
                        <p className="text-slate-500 text-xs">{item?.orderid}</p>
                      </div>
                      <div className='text-center'>
                        <h4 className="text-slate-700 text-sm mb-2 font-semibold">Date of oder placed</h4>
                        <p className="text-slate-500 text-xs">{formatDate(item?.created_at)}</p>
                      </div>
                      <div className='text-center'>
                        <h4 className="text-slate-700 text-sm mb-2 font-semibold">Total amount</h4>
                        <p className="text-slate-700 text-xs font-semibold">{calTotal(item?.amount, item?.tax).toFixed(2)} BDT</p>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <Link to={`/customer/${item?.orderid}/orders-view`} className="border border-slate-400 text-slate-500 text-sm cursor-pointer hover:bg-slate-200 hover:text-slate-900 rounded-md px-3 py-1.5 shadow">View Order</Link>
                    </div>
                  </div>

                  {/* order body  */}
                  {
                    productsParse(item?.products).map((product, i) => (
                      <div key={i} className='border-b border-gray-300 pb-4'>
                        <div className="flex justify-between items-center px-4 py-4 gap-2">
                          <div className="w-32">
                            <img className='rounded-md' src={`${app_url}/storage/${product?.thumbnail}`} alt="" />
                          </div>
                          <div className="flex-1">
                            <div className="flex justify-between items-center">
                              
                              <h2 className="text-lg text-slate-700">{product?.name}</h2>
                              <h2 className="text-sm text-slate-800">{product?.cartQuantity} X {(product?.price).toFixed(2)} { product?.tax && <>+ {product?.tax}% Tax</>}</h2>
                            </div>
                            <div className='mt-2'>
                              <p className='text-slate-500 text-sm'>
                                <span className='font-semibold'>Shipping info:</span> Type: {item?.shipping_type}, Charge: {item?.shipping_cost} TK
                              </p>
                              <p className='text-slate-500 text-sm mt-1'>
                                <span className='font-semibold'>Shipping address:</span> {item?.address}
                              </p>
                              <p className='text-slate-500 text-sm mt-1'>
                                <strong className='font-semibold'>Attributes info:</strong>
                                <ul className='flex gap-4'>
                                {product?.attributes.map((attribute, attrIndex) => {
                                    if (attribute?.name === 'colors') {
                                      return (
                                        <li key={attrIndex}>
                                          <span className='capitalize font-semibold'>{attribute?.name}:</span> <span className='text-white px-2 py-0 rounded-xl' style={{ backgroundColor: attribute?.value?.value }} >{attribute?.value?.title}</span>
                                        </li>
                                      );
                                    }
                                    return (
                                      <li key={attrIndex}>
                                        <span className='capitalize font-semibold'>{attribute?.name}:</span> {typeof attribute?.value === 'object' && attribute?.value !== null
                                          ? `${attribute?.value?.title} (${attribute?.value?.value})`
                                          : attribute?.value}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center px-4">
                          <div className='flex items-center gap-1'>
                            {
                              item?.status?.code === 1 ? (
                                <>
                                <p className='text-sm mt-0.5'>Order Status: <span className='bg-gray-600 px-3 rounded-xl text-white font-semibold'>Pending</span></p>
                                </>
                              ) :
                              item?.status?.code === 2 ? (
                                <p className='text-sm mt-0.5'>Order Status: <span className='bg-teal-700 px-3 rounded-xl text-white font-semibold'>Processing</span></p>
                              ) :
                              item?.status?.code === 3 ? (
                                <p className='text-sm mt-0.5'>Order Status: <span className='bg-blue-600 px-3 rounded-xl text-white font-semibold'>Confirmed</span></p>
                              ) :
                              item?.status?.code === 6 ? (
                                <p className='text-sm mt-0.5'>Order Status: <span className='bg-red-600 px-3 rounded-xl text-white font-semibold'>Canceled</span></p>
                              ) :
                              item?.status?.code === 4 ? (
                                <>
                                <CheckCircleIcon style={{ fontSize: '25px', color: 'rgb(0 180 77)' }} />
                                <p className='text-md font-semibold mt-0.5 text-slate-500'>Delivered on July 12, 2021</p>
                                </>
                              ) : ''
                            }
                          </div>
                          <Link to={`/product-view/${product?.slug}`} className='text-green-700'>View Product</Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
            
            </>
          }
          </>
        )
      }
    </div>
    </>
  )
}

export default OrderHistory

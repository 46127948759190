// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DehazeIcon from '@mui/icons-material/Dehaze';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { TECollapse } from "tw-elements-react";
import { app_url, url } from '../../../features/api';
import { logoutUser } from '../../../features/authSlice';
import oldLogo from '../../../images/Navbar/nikhad.png';

const Navbar = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [searchBtn, setSearchBtn] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const ref = useRef(null);
    const navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.category?.categories);
    const [loading, setLoading] = useState(true);
    const info = useSelector((state) => state?.siteInfo?.info);
    const { value } = useParams();

    const [activeElement, setActiveElement] = useState("");
  const [preActiveElement, setPreActiveElement] = useState("element177");

  const handleClick = (val) => {
    if (val === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(val);
    }
  };
  
  useEffect(() => {
    if (value && categories && categories?.data && Array.isArray(categories?.data)) 
    {
      const filteredData = categories?.data.filter(category => category?.slug === value);
      const childData = filteredData?.map(category => category?.child_categories?.length);

      if (filteredData && Array.isArray(filteredData) && filteredData?.length > 0) {
        if(childData > 0){
          const filteredIds = filteredData?.map(category => category?.id);
          setActiveElement(`element${filteredIds[0]}`);
        }else{
          const filteredChildIds = filteredData?.map(category => category?.parent_id);
          setActiveElement(`element${filteredChildIds[0]}`);
        }

      } else {
        setActiveElement("");
      }
    }
  }, [value, categories]);

    useEffect(() => {
      const timer = setTimeout(()=>{
        setLoading(false)
      }, 500)
    
      return () => clearTimeout(timer);
    }, [])
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = (event) => {
      const { value } = event.target;
      setSearchTerm(value);
      if (value?.length > 1) {
        setSearchBtn(true)
      }else{
        setSearchBtn(false)
      }
    };

    const mainSearch = ()=>{
      navigate(`/search/${searchTerm}`)
    }

    const userName = auth?.name === null ? '' : auth?.name ;
    const words = userName?.split(' ');
    const firstLetter = words[0][0]?.toUpperCase();
    const secondLetter = words[1] ? words[1][0]?.toUpperCase() : '';
    const avatar = firstLetter + secondLetter;

    const userLoggedOut = () =>{
      const payload = {
        phone: auth?.phone
      }
      axios.post(`${url}/user-logout`, payload)
      .then(response => {
        dispatch(logoutUser(null))
        toast.warning("You has been logged out!", {
          position: "top-right",
        });
        navigate("/login")
      })
      .catch(err => {
        toast.error('Something went wrong', {
          position: "bottom-right",
        });
        console.log('error', err)
      });

    }
  
  
    return (
        <nav className="px-4 md:px-10 2xl:px-20 py-5 shadow-[0_10px_12px_rgb(0,0,0,24%)] sticky top-10 left-0 bg-white z-20">
            <div className="sm:container p-1 sm:p-0 mx-auto">
                <div className="flex items-center gap-5 2xl:gap-20">
                    <div className="flex items-center justify-between w-full lg:w-auto">
                        <a href="/">
                            {
                              loading ? (
                                <img className='min-w-18 h-12' src={oldLogo} alt="logo" />
                              ) : (
                                <img className='min-w-18 h-12' src={`${app_url}/${info?.data?.logo}`} alt="logo" />
                              )
                            }
                        </a>
                        {/* toggle icon   */}
                        <div className="text-3xl lg:hidden" onClick={() => setOpen(!open)}>
                            <DehazeIcon />
                        </div>
                    </div>
                    <div className="hidden w-full font-['Lato'] lg:flex justify-between items-center gap-5 2xl:gap-20">
                        <div className="search w-1/2 relative">
                          <span className='w-10 absolute left-1 text-slate-500 top-2 rounded'>
                            <SearchIcon />
                          </span>
                          <input
                              type="search"
                              name="search"
                              placeholder="Search for product..."
                              className="w-full bg-slate-100 py-3 px-8 outline-none rounded-md text-xs"
                              value={searchTerm}
                              onChange={handleSearch}
                          />
                          {
                            searchBtn ? 
                          <button onClick={()=>mainSearch()} className='w-20 absolute right-0 top-0 text-slate-100 bg-green-700 py-2 px-2 rounded'>
                            Search
                          </button> :
                          <button disabled onClick={()=>mainSearch()} className='w-20 absolute right-0 top-0 text-slate-200 bg-slate-400 py-2 px-2 rounded'>
                          Search
                        </button>
                          }
                        </div>
                        
                        <div className="w-fit">
                            <ul className="flex items-center justify-between gap-5">
                                <li>
                                  <a href='/' className={`${location?.pathname === '/' ? 'text-green-700 font-semibold' : ' text-gray-600'} hover:text-green-600 font-normal`}>Home</a>
                                </li>
                                <li>
                                  <a href='/products' className={`${location?.pathname === '/products' ? 'text-green-700 font-semibold' : ' text-gray-600'} hover:text-green-600 font-normal`}>Shop</a>
                                </li>
                                <li>
                                  <div className="dropdown inline-block relative">
                                  <button className="text-gray-600 font-normal inline-flex items-center">
                                    <span className="mr-1">Category</span>
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                                  </button>
                                  <ul className="dropdown-menu absolute hidden rounded bg-gray-200 text-gray-700 pt-1 w-60 right-0">
                                    <li>
                                      <a className="hover:bg-slate-50 hover:text-green-700 rounded text-sm py-2 px-4 block" href='/categories/new-arrivals'>New Arrivals</a>
                                    </li>
                                    <li>
                                      <a className="hover:bg-slate-50 hover:text-green-700 rounded text-sm py-2 px-4 block" href='/categories/sales-products'>Best Selling Products</a>
                                    </li>
                                    <li>
                                      <a className="hover:bg-slate-50 hover:text-green-700 rounded text-sm py-2 px-4 block" href='/categories/discount-products'>Discount Products</a>
                                    </li>
                                    {categories?.data?.map((category, index)=>(
                                      <li key={index}>
                                        {
                                          !category?.parent_id ? (
                                            <a className="hover:bg-slate-50 hover:text-green-700 rounded text-sm py-2 px-4 block" href={`/categories/${category?.slug}`}>{category?.name}</a>
                                          ) : ''
                                        }
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                </li>
                                
                                {
                                  auth?.id ? (
                                    <li>
                                      <div className="dropdown inline-block relative">
                                      <button className="text-gray-600 font-normal inline-flex items-center">
                                        <span className='w-9 h-9 flex items-center justify-center rounded-full border-2 border-[#006B2E] shadow-lg'>{avatar}</span>
                                      </button>
                                      <ul className="dropdown-menu shadow-lg rounded absolute left-[-5rem] hidden bg-slate-50 text-gray-700 pt-1 w-40">
                                        
                                        {
                                          auth?.phone_verified_at?.length > 4 ?  <>
                                          <li className="">
                                          <a className={`${location?.pathname === '/customer/profile' ? 'text-green-700 font-semibold' : ' '} hover:bg-slate-200 text-sm py-2 px-4 block`} href="/customer/profile">My Profile</a>
                                        </li>
                                        <li className="">
                                          <a className={`${location?.pathname === '/customer/orders' ? 'text-green-700 font-semibold' : ''} 'hover:bg-slate-200 text-sm py-2 px-4 block`} href="/customer/orders">My Orders</a>
                                        </li>
                                        {/* <li className="">
                                          <a className={`${location?.pathname === '/order/track' ? 'text-green-700 font-semibold' : ''} 'hover:bg-slate-200 text-sm py-2 px-4 block`} href="/order/trace">Trace Order</a>
                                        </li> */}
                                          </> : ' '
                                        }

                                        <li className="cursor-pointer">
                                          <span 
                                          className="hover:bg-slate-200 text-sm py-2 px-4 block"
                                          onClick={()=> userLoggedOut()}
                                          >Logout</span>
                                        </li>
                                      </ul>
                                      </div>
                                    </li>
                                  ) : (
                                    <a href='/login'
                                    className="py-2.5 px-4 rounded-md text-white text-xs uppercase bg-[#006B2E] hover:bg-[rgb(73_53_72)]">
                                    <span className="icon-profile" /> My account
                                    </a>
                                  )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                
                {/* backdrop filter  */}
                <div
                    className={`bg-[#000] fixed top-0 left-0 w-[100vw] h-[100vh] lg:hidden transition-opacity ${
                        open ? 'opacity-50 block' : 'opacity-0 hidden'
                    }`}
                />
                <div
                    className={`lg:hidden bg-white fixed bottom-0 w-72 top-4 duration-500 py-10 z-50 overflow-y-scroll ${
                        open ? 'left-0' : 'left-[-100%]'
                    }`}
                    ref={ref}
                >
                    <div className="flex items-start px-5 flex-col gap-5">
                    <div className="search w-full relative">
                          
                          <input
                              type="search"
                              name="search"
                              placeholder="Search for product..."
                              className="w-full bg-slate-100 py-3 px-8 outline-none rounded-md text-xs"
                              value={searchTerm}
                              onChange={handleSearch}
                          />
                          {
                            searchBtn ? 
                          <button onClick={()=>mainSearch()} className='w-12 absolute right-0 top-0 text-slate-100 bg-green-700 py-2 px-1 rounded'>
                            <SearchIcon />
                          </button> :
                          <button disabled onClick={()=>mainSearch()} className='w-12 absolute right-0 top-0 text-slate-200 bg-slate-400 py-2 px-1 rounded'>
                            <SearchIcon />
                        </button>
                          }
                        </div>
                        <div className="">
                            <ul className="flex flex-col items-start justify-between gap-5">
                                <li>
                                  <a href='/' className={`${location?.pathname === '/' ? 'text-green-700 font-semibold' : ' text-gray-600'} hover:text-green-600 font-normal`}>Home</a>
                                </li>
                                <li>
                                  <a href='/products' className={`${location?.pathname === '/products' ? 'text-green-700 font-semibold' : ' text-gray-600'} hover:text-green-600 font-normal`}>Shop</a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full">
                          <div className='pb-2'>
                            <div className="categories mt-1">
                              <div className='w-full mb-1'>
                                <a
                                  href='/categories/new-arrivals'
                                  className={`${
                                    value === `new-arrivals` &&
                                    `text-gray-600 bg-green-700`
                                  } cursor-pointer flex w-full items-center font-normal rounded py-1.5 text-lg text-gray-800 hover:bg-white hover:text-[#006B2E]`}
                                >
                                  New Arrivals
                                </a>
                              </div>
                              <div className='w-full mb-1'>
                                <a
                                  href='/categories/sales-products'
                                  className={`${
                                    value === `sales-products` &&
                                    `text-gray-600 bg-green-700`
                                  } cursor-pointer flex w-full items-center font-normal rounded py-1.5 text-lg text-gray-800 hover:bg-white hover:text-[#006B2E]`}
                                >
                                  Sales Products
                                </a>
                              </div>
                              <div className='w-full mb-1'>
                                <a
                                  href='/categories/discount-products'
                                  className={`${
                                    value === `discount-products` &&
                                    `text-gray-600 bg-green-700`
                                  } cursor-pointer flex w-full items-center font-normal rounded py-1.5 text-lg text-gray-800 hover:bg-white hover:text-[#006B2E]`}
                                >
                                  Discount Products
                                </a>
                              </div>
                            </div>
                            {
                              categories?.data?.map((category, index)=> (
                                <div key={index} className="categories mt-1">
                                  {
                                    category?.child_categories?.length ? ( 
                                      <div id="accordionExample" className='w-full mb-1'>
                                      <a
                                        type='button'
                                        className={`${
                                          activeElement === `element${category?.id}` &&
                                          `text-slate-800 bg-white rounded-b-none`
                                        } flex w-full items-center font-normal rounded py-1.5 text-lg text-gray-600 hover:bg-white hover:text-[#006B2E]`}
                                        onClick={() => handleClick(`element${category?.id}`)}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        <a href={`/categories/${category?.slug}`}>
                                        {category?.name}
                                        </a>
                                        <span
                                          className={`${
                                            activeElement === `element${category?.id}`
                                              ? `rotate-[-180deg] -mr-1`
                                              : `rotate-0 fill-[#006B2E]`
                                          } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none `}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                            />
                                          </svg>
                                        </span>
                                      </a>
                                      <TECollapse
                                        show={activeElement === `element${category?.id}`}
                                        className="!mt-0 !rounded-t-none !shadow-none border-0 bg-slate-100"
                                      >
                                        <ul className="px-2 py-1 w-full">
                                          {
                                          category?.child_categories?.map((child, i)=>(
                                            <li key={i} className={`${
                                              value === `${child?.slug}` &&
                                              `text-slate-100 hover:text-green-800 bg-green-700`
                                              } mb-1 py-1 hover:bg-white rounded`}>
                                              <a className='' 
                                                href={`/categories/${child?.slug}`}
                                              >- {child?.name}</a>
                                            </li>
                                            ))
                                          }
                                        </ul>
                                      </TECollapse>
                                  </div>
                                    ) : (
                                      <>
                                      {
                                        category?.parent_id < 1 && (
                                          <div className='w-full mb-1'>
                                        <a
                                          href={`/categories/${category?.slug}`}
                                          className={`${
                                            value === `${category?.slug}` &&
                                            `text-gray-600 bg-green-700`
                                          } flex w-full items-center font-normal rounded py-1.5 text-lg text-gray-800 hover:bg-white hover:text-[#006B2E]`}
                                          type="button"
                                        >
                                          {category?.name}
                                        </a>
                                      </div>
                                        )
                                      }
                                      </>
                                    )
                                  }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <div className="border-t w-full py-2">
                            <ul className="flex flex-col items-start justify-between gap-5">
                                {
                                  auth?.id ? (
                                    <>
                                    <div className="flex justify-between gap-x-6 pb-2 border-b border-slate-200">
                                      <div className="flex min-w-0 gap-x-4">
                                      <span className='w-8 h-8 flex items-center justify-center rounded-full border-2 border-[#006B2E] shadow-lg'>{avatar}</span>
                                      <div className="min-w-0 flex-auto">
                                          <p className="text-sm font-semibold leading-6 text-slate-700 capitalize mb-1">{userName}</p>
                                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{auth?.phone}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <ul role="list">
                                      {
                                        auth?.phone_verified_at?.length > 4 ? <>
                                          <li className="flex justify-between gap-x-6 pb-1">
                                        <a href='/customer/profile' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/profile' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
                                          <div className="min-w-0 flex-auto">
                                            <p className="font-normal leading-6">My Profile</p>
                                          </div>
                                        </a>
                                      </li>
                                      <li className="flex justify-between gap-x-6 pb-1">
                                        <a href='/customer/orders' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/orders' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
                                          <div className="min-w-0 flex-auto">
                                            <p className="font-normal leading-6">My Orders</p>
                                          </div>
                                        </a>
                                      </li>
                                      <li className="flex justify-between gap-x-6 pb-1">
                                        <a href='/customer/settings' className={`flex items-center cursor-pointer min-w-full px-2 py-1 gap-x-4 rounded ${location?.pathname === '/customer/settings' ? 'bg-[rgb(61,46,60)] hover:bg-green-700 text-slate-50' : 'hover:bg-slate-200 text-slate-700'}`}>
                                          <div className="min-w-0 flex-auto">
                                            <p className="font-normal leading-6">Settings</p>
                                          </div>
                                        </a>
                                      </li>
                                        </> : ''
                                      }
                                      
                                      

                                      <li className="cursor-pointer">
                                          <span 
                                          className="hover:bg-slate-200 text-sm py-2 px-4 block"
                                          onClick={()=>{
                                            dispatch(logoutUser(null))
                                            toast.warning("You has been logged out!", {
                                              position: "top-right",
                                            });
                                            navigate("/login")
                                          }}
                                          >Logout</span>
                                        </li>
                                    </ul>
                                    </>
                                  ) : (
                                    <a href='/login'
                                    className="py-2.5 px-4 rounded-md text-white text-xs uppercase bg-[#006B2E] hover:bg-[rgb(73_53_72)]">
                                    <span className="icon-profile" /> My account
                                    </a>
                                  )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="pt-5 hidden lg:block">
                    <ul className="flex items-center justify-around text-xs font-['Lato']">
                        <li>
                            <Link to="/">All Categories</Link>
                        </li>
                        <li>
                            <Link to="/">Accessories</Link>
                        </li>
                        <li>
                            <Link to="/">Smartphone</Link>
                        </li>
                        <li>
                            <Link to="/">Computer</Link>
                        </li>
                        <li>
                            <Link to="/">Gaming Equipment</Link>
                        </li>
                        <li>
                            <Link to="/">TV & Monitor</Link>
                        </li>
                        <li>
                            <Link to="/">Headphones</Link>
                        </li>
                        <li>
                            <Link to="/">Speakers</Link>
                        </li>
                        <li>
                            <Link to="/">HOT DEALS</Link>
                        </li>
                    </ul>
                </div> */}
            </div>
        </nav>
    );
};

export default Navbar;

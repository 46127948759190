import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from '../../features/productsSlice';
import ProductCard from '../Shared/CommonCard/ProductCard';
import Pagination from '../Shared/Pagination/Pagination';
import Sidebar from '../Shared/Shop/Sidebar';

const Shop = () => {
    const cartData = useSelector((state) => state.cart);
    const favoriteData = useSelector((state) => state.favorite);
    const dispatch = useDispatch();
    const [animationParent] = useAutoAnimate();
    
    const totalProducts = useSelector((state) => state.products);
    const allProducts = totalProducts.products?.data?.data;
    const [data, setData] = useState(allProducts || []);

    useEffect(() => {
      if (allProducts) {
        setData(allProducts);
      }
    }, [allProducts]);
    
    useEffect(() => {
      dispatch(getProducts());
    }, [cartData, favoriteData, dispatch]);

    const [sortOption, setSortOption] = useState('');

    const handleSort = (event) => {
      const selectedSortOption = event.target.value;
      setSortOption(selectedSortOption);

      const sortedProducts = [...data].sort((a, b) => {
        if (selectedSortOption === 'name-asc') {
          return a.name.localeCompare(b.name);
        }else if(selectedSortOption === 'name-desc'){
          return b.name.localeCompare(a.name);
        }else if(selectedSortOption === 'price-asc'){
          return a.price - b.price;
        }else if(selectedSortOption === 'price-desc'){
          return b.price - a.price;
        }
        return 0;
      });

      setData(sortedProducts);
    };

    return (
        <section className="my-10 px-4 min-h-screen">
          <div className="flex gap-6">
            {/* sidebar-area */}
            <div className="hidden md:w-80 md:inline-block">
              <Sidebar/>
            </div>
            
            {/* content-area */}
            <div className="flex-1">
              <div className="pb-2.5 rounded-md block sm:flex items-center justify-between">
                <div>
                    <h2 className="font-semibold text-xl text-gray-700 mb-1 font-['sans-serif']">All Items</h2>
                    <p className="font-normal text-md text-gray-700">Find your items and get ready to order them!</p>
                </div>
                <div className="flex items-center gap-2 mt-2 sm:mt-0">
                  <select value={sortOption} onChange={handleSort} class="pl-4 pr-10 py-2.5 border border-slate-300 rounded text-sm focus:outline-none focus:border-gray-300">
                    <option hidden="" value="">Select sorting</option>
                    <option value="name-asc">By name A-Z</option>
                    <option value="name-desc">By name Z-A</option>
                    <option value="price-asc">From lowest to highest</option>
                    <option value="price-desc">From highest to lowest</option>
                  </select>
                </div>
              </div>

              <div ref={animationParent} className="mt-4 flex flex-wrap items-center gap-7 justify-center 2xl:justify-between">
                <ProductCard products={data} cartData={cartData.cartItems} favoriteData={favoriteData.favoriteItems} />
              </div>
                <Pagination />
            </div>
          </div>
            
        </section>
    );
};

export default Shop;

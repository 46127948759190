/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const TrackOrder = () => (
    <section className="my-10 px-4 md:px-10 2xl:px-20">
        <div className="container mx-auto">
            <h1 className="text-lg font-bold">Track Your Order</h1>
            <form action="">
                <div className="flex flex-wrap md:flex-nowrap items-center gap-5 lg:gap-10 mt-10">
                    <div className="w-full">
                        <label htmlFor="email">Email Address</label>
                        <br />
                        <input
                            type="email"
                            name="email"
                            className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                            required
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="orderId">Order Id</label>
                        <br />
                        <input
                            type="text"
                            name="orderId"
                            className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                            required
                        />
                    </div>
                </div>
                <div className="grid md:flex md:justify-end mt-6">
                    <button
                        className="bg-[rgb(73_53_72)] text-white py-2 px-12 rounded-md"
                        type="submit"
                    >
                        Track Your Order
                    </button>
                </div>
            </form>
        </div>
    </section>
);

export default TrackOrder;

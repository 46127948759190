import axios from "axios";
import React, { useEffect, useState } from 'react';
import { setNHeaders, url } from '../../features/api';

const Faq = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${url}/extra-pages`, setNHeaders())
     .then(response => {
       setData(response?.data?.res);
     })
     .catch(err => {
       setError(err);
     })
     .finally(() => {
       setLoading(false);
     });
 }, []);

  return (
    <section className='min-h-screen py-6'>
      <div className="sm:container px-4 sm:px-0 mx-auto">
        <h2 className="text-slate-600 font-semibold text-2xl mt-5 mb-10 text-center">Frequently Asked Questions ?</h2>
        {
          loading ? <>
          <div className="content mb-6">
            <h3 className="bg-slate-500 w-64 h-7 rounded-sm mb-1.5"></h3>
            <p className="bg-slate-400 w-2/3 h-5 rounded-sm mb-1"></p>
            <p className="bg-slate-400 w-full h-5 rounded-sm mb-1"></p>
            <p className="bg-slate-400 w-2/4 h-5 rounded-sm"></p>
          </div>
          </> : <>
            {
              data?.faq.map((item, i) => (
              <div key={i} className="content mb-6">
                <h3 className="text-slate-600 font-medium text-xl mb-1">{item?.question}</h3>
                <p className="text-slate-600 text-base">{item?.answer}</p>
              </div>
              ))
            }
            
          </>
        }

      </div>
    </section>
  )
}

export default Faq

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fetchSlider } from '../../../features/topSliderSlice';

const TopBanner = () => {
  const slider = useSelector((state) => state.topSlider?.slider?.data);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(()=>{
      setLoading(false)
    }, 500)
  
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    dispatch(fetchSlider());
  }, [dispatch])

    return (
        <section className="my-10 px-4 md:px-10 2xl:px-20">
            <div className="mx-auto">
              {
                loading ? (
                  <div className="w-full p-12 pb-12 h-[400px] rounded bg-slate-600">
                      <div className="w-1/3 h-8 mt-6 bg-slate-500 rounded"></div>
                      <div className="w-2/3 h-10 mt-6 bg-slate-500 rounded"></div>
                      <div className="w-48 h-12 mt-6 bg-slate-400 rounded"></div>
                  </div>
                ) : (
                  <>
                  {
                    slider?.length > 0 ? (
                      <>
                      <Swiper
                          effect="coverflow"
                          grabCursor
                          centeredSlides
                          slidesPerView="auto"
                          coverflowEffect={{
                              rotate: 0,
                              stretch: 0,
                              depth: 100,
                              modifier: 1,
                              slideShadows: true,
                          }}
                          pagination={{
                              clickable: true,
                          }}
                          spaceBetween={20}
                          autoplay={{
                              delay: 4500,
                              disableOnInteraction: false,
                          }}
                          loop
                          modules={[EffectCoverflow, Pagination, Autoplay]}
                          className="w-full pb-12"
                      >
                          {slider.map((data) => (
                              <SwiperSlide key={data.id} className="w-full h-[320px] sm:h-[420px]">
                                  <img
                                      className="w-full block h-full rounded-md object-cover relative"
                                      src={data?.image}
                                      alt={data?.title}
                                  />
                                  <h3 className='absolute top-16 left-6 sm:left-20 mt-12 text-xl'>{data?.sub_title}</h3>
                                  <h2 className="absolute top-24 sm:top-28 left-6 sm:left-20 text-3xl sm:text-5xl font-semibold mt-10 font-['sans-serif']">{data?.title}</h2>
                                  <a href={data?.url} className="absolute top-32 sm:top-44 left-6 sm:left-20 mt-12 py-2 px-6 sm:py-3 sm:px-10 rounded text-white text-base uppercase bg-gradient-to-r from-[#006B2E] to-[#006B2E]"
                                        >{data?.btn_name}</a>
                              </SwiperSlide>
                          ))}
                      </Swiper>
                      </>
                    ) : (
                      <div className="w-full p-12 pb-12 h-[380px] rounded bg-slate-600 flex justify-center items-center">
                        <div className='text-center'>
                        <h2 className="font-semibold text-3xl mb-3 font-[sans-serif] text-slate-200"> No Slider Yet!</h2>
                        <p className="text-sm text-slate-200">If you are admin? please add from admin panel</p>
                        </div>
                      </div>
                    )
                  }
                  </>
                )
              }
                
            </div>
        </section>
    );
};
export default TopBanner;

import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { addToCart, clearCart, decreaseCart, removeFromCart } from '../../features/cartSlice';
import FavoriteCard from '../Shared/CommonCard/FavoriteCard';

const Favorite = () => {
    const cartData = useSelector((state) => state.cart);
    const favoriteData = useSelector((state) => state.favorite);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddToCart = (data) => {
      dispatch(addToCart(data));
    };

    const handleSelectAll = () => {
      setIsChecked(!isChecked);
      if (!isChecked) {
        const allIds = cartData?.cartItems?.map(item => item.id);
        setSelectedIds(allIds);
      }else{
        setSelectedIds([]);
      }
    };

    const handleCheckboxChange = id => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter(itemId => itemId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    };

    const handleClearCart = () => {
      dispatch(clearCart());
      setIsChecked(!isChecked);
    };
    
    const handleDecreaseCart = (data) => {
      dispatch(decreaseCart(data));
    };

    const handleRemoveFromCart = () => {
      dispatch(removeFromCart({ ids: selectedIds }));
    };
    const [data, setData] = useState([]);

    useEffect(() => {
      setData(favoriteData.favoriteItems)
    }, [cartData, favoriteData, dispatch]);

    const [animationParent] = useAutoAnimate();


    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState('');

  
    const handleSearch = (event) => {
      const { value } = event.target;
      setSearchTerm(value);
    };

    const products = data.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSort = (event) => {
      const selectedSortOption = event.target.value;
      setSortOption(selectedSortOption);

      const sortedProducts = [...data].sort((a, b) => {
        if (selectedSortOption === 'name-asc') {
          return a.name.localeCompare(b.name);
        }else if(selectedSortOption === 'name-desc'){
          return b.name.localeCompare(a.name);
        }else if(selectedSortOption === 'price-asc'){
          return a.price - b.price;
        }else if(selectedSortOption === 'price-desc'){
          return b.price - a.price;
        }
        return 0;
      });

      setData(sortedProducts);
    };

    return (
        <section className="my-10 px-4 md:px-10 2xl:px-20 min-h-screen">
            <div className="container mx-auto">
              <div className=" pb-2.5 rounded-md flex items-center justify-between">
                  <div>
                      <h2 className="font-semibold text-3xl text-gray-700 mb-1 font-['sans-serif']">Favorites</h2>
                      <p className="font-normal text-md text-gray-700">Find your saved items and get ready to order them!</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <input 
                      className='w-56 bg-gray-50 text-sm font-light text-gray-600 border border-gray-300 py-2.5 px-2 rounded focus:outline-none focus:border-gray-200'
                      type="text" 
                      placeholder='Search...' 
                      value={searchTerm}
                      onChange={handleSearch}
                     />
                    <select value={sortOption} onChange={handleSort} class="pl-4 pr-10 py-2.5 border border-primary rounded text-sm focus:outline-none focus:border-gray-300">
                      <option hidden="" value="">Select sorting</option>
                      <option value="name-asc">By name A-Z</option>
                      <option value="name-desc">By name Z-A</option>
                      <option value="price-asc">From lowest to highest</option>
                      <option value="price-desc">From highest to lowest</option>
                    </select>
                  </div>
              </div>

              <div ref={animationParent} className="mt-10 flex flex-wrap items-center gap-5 justify-center 2xl:justify-between">
                    <FavoriteCard products={products} favorite={favoriteData.favoriteItems} cartData={cartData.cartItems} searchTerm={searchTerm} />
              </div>
            </div>
        </section>
    );
};

export default Favorite;

/* global fbq */

// Function to load the Facebook Pixel script dynamically
const loadFacebookPixelScript = () => {
  (function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
};

// Initialize the Pixel with the given pixel ID
export const initFacebookPixel = (pixelId) => {
  // Load the script
  loadFacebookPixelScript();

  // Initialize the pixel with the given ID
  fbq("init", pixelId);
  fbq("track", "PageView");
};

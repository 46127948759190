import { useAutoAnimate } from '@formkit/auto-animate/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSearchProducts } from '../../../features/productsSlice';
import ProductCard from '../../Shared/CommonCard/ProductCard';

const SearchProducts = () => {
  const { value } = useParams();
  const [showCategory, setShowCategory] = useState(true);
  const [animationParent] = useAutoAnimate();
  const cartData = useSelector((state) => state.cart);
  const favoriteData = useSelector((state) => state.favorite);
  const dispatch = useDispatch();
  const totalProducts = useSelector((state) => state.products);
  const searchProducts = totalProducts?.searchProducts?.data;
  const [data, setData] = useState(searchProducts || []);

 

  useEffect(() => {
    if (searchProducts) {
      setData(searchProducts);
    }
  }, [searchProducts]);
  
  const fetchDataBasedOnValue = (value) => {
    if (value) {
      dispatch(getSearchProducts(value))
    }
  };

  useEffect(() => {
    fetchDataBasedOnValue(value);
  }, [value, dispatch]);

  const [sortOption, setSortOption] = useState('');

  const handleSort = (event) => {
    const selectedSortOption = event.target.value;
    setSortOption(selectedSortOption);

    const sortedProducts = [...data].sort((a, b) => {
      if (selectedSortOption === 'name-asc') {
        return a.name.localeCompare(b.name);
      }else if(selectedSortOption === 'name-desc'){
        return b.name.localeCompare(a.name);
      }else if(selectedSortOption === 'price-asc'){
        return a.price - b.price;
      }else if(selectedSortOption === 'price-desc'){
        return b.price - a.price;
      }
      return 0;
    });

    setData(sortedProducts);
  };
  

  return (
    <section className="my-10 px-4 md:px-10 2xl:px-20 min-h-screen">
      <div className="sm:container mx-auto">
        <div className="pb-2.5 rounded-md block sm:flex items-center justify-between">
          <div>
              <h2 className="font-semibold text-3xl text-gray-700 mb-1 font-['sans-serif']">Search results for <span className='text-green-700'>{value}</span></h2>
              <p className="font-normal text-md text-gray-700">Find your search items and get ready to order them!</p>
          </div>
          <div className="flex items-center gap-2 mt-2 sm:mt-0">
            {/* <input 
              className='w-56 bg-gray-50 text-sm font-light text-gray-600 border border-gray-300 py-2.5 px-2 rounded focus:outline-none focus:border-gray-200'
              type="text" 
              placeholder='Search...' 
              /> */}
              <select value={sortOption} onChange={handleSort} class="pl-4 pr-10 py-2.5 border border-slate-300 rounded text-sm focus:outline-none focus:border-gray-300">
                <option hidden="" value="">Select sorting</option>
                <option value="name-asc">By name A-Z</option>
                <option value="name-desc">By name Z-A</option>
                <option value="price-asc">From lowest to highest</option>
                <option value="price-desc">From highest to lowest</option>
              </select>
          </div>
        </div>
        <div className="w-full">
            <div ref={animationParent} className="mt-4 grid grid-cols-5 items-center gap-5 justify-center 2xl:justify-between">
              <ProductCard products={data} cartData={cartData.cartItems} favoriteData={favoriteData.favoriteItems} />
            </div>
        </div>
      </div>
    </section>
  )
}

export default SearchProducts;

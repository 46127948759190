/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const CancelOrder = () => (
    <section className="my-10 px-4 md:px-10 2xl:px-20 lg:mb-20">
        <div className="container mx-auto">
            <h1 className="text-lg font-bold">Report/Cancel Order</h1>
            <form action="">
                <div className="flex flex-wrap md:flex-nowrap items-center gap-5 lg:gap-10 mt-10">
                    <div className="w-full">
                        <label htmlFor="orderId">Order Id</label>
                        <br />
                        <input
                            type="text"
                            name="orderId"
                            className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                            required
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="email">Email Address</label>
                        <br />
                        <input
                            type="email"
                            name="email"
                            className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap items-center gap-5 lg:gap-10 mt-6">
                    <div className="w-full">
                        <label htmlFor="phone">Phone No</label>
                        <br />
                        <input
                            type="number"
                            name="phone"
                            className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                            required
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="proof">Proof (Video or any files for proof)</label>
                        <br />
                        <input type="file" name="proof" className="mt-2 h-8" />
                    </div>
                </div>
                <div className="w-full gap-5 lg:gap-10 mt-6">
                    <label htmlFor="reason">Report/Reason for cancelation</label>
                    <br />
                    <textarea
                        name="reason"
                        rows="4"
                        className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md px-2"
                        required
                    />
                </div>
                <div className="grid md:flex md:justify-end mt-6">
                    <button
                        className="bg-[rgb(73_53_72)] text-white py-2 px-12 rounded-md"
                        type="submit"
                    >
                        Submit Now
                    </button>
                </div>
            </form>
        </div>
    </section>
);

export default CancelOrder;

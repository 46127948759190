import axios from "axios";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { setNHeaders, url } from "../../../features/api";

const ProfileComponent = () => {
  const auth = useSelector((state) => state.auth);
  const [name, setUsername] = useState(auth?.name);
  const [errors, setErrors] = useState({
    name: ''
  });


  const validateForm = () => {
    const newErrors = {
      name: name ? '' : 'Name is required.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        name : name
      }
      axios.post(`${url}/update-profile`, payload, setNHeaders())
      .then(response => {
        localStorage.setItem("name", response?.data?.name)
        toast.success(response?.data?.message, {
          position: "top-right",
        });
      })
      .catch(err => {
        toast.error("Something went wrong!", {
          position: "bottom-right",
        });
      });
    } else {
      toast.error("Form contains errors!", {
        position: "bottom-right",
      });
    }
  }  
  
  
  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-xl font-normal leading-7 text-green-700">My Profile</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This information will be your identity so be careful what you share.
          </p>
          <div className="mt-10">
            <label htmlFor="name" className="block text-sm font-normal leading-6 text-slate-800">
              Full Name
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='Type your full name'
              className={`${ errors?.name ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
            />
            <span className="text-sm text-red-600">{errors.name}</span>
          </div>
          <div className="mt-10">
            <label htmlFor="phone" className="block text-sm font-normal leading-6 text-slate-800">
              Your Phone No
            </label>
            <input
              type="text"
              name="phone"
              value={auth?.phone}
              disabled
              className="block border border-[rgb(212_212_212)] w-full mt-2 py-1.5 pl-1 rounded-md h-10 px-2 text-gray-600 text-sm"
            />
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[rgb(61,46,60)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[rgb(73_53_72)]"
        >
          Save Change
        </button>
      </div>
    </form>
    </>
  )
}

export default ProfileComponent

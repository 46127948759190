import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./api";

const initialState = {
  slider: [],
  status: null,
  error: null,
};

export const fetchSlider = createAsyncThunk('slider/fetchSlider', async () => {
  try {
    const response = await axios.get(`${url}/top-slider`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const topSliderSlice = createSlice({
  name: "topSlider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSlider.pending, (state) => {
      state.status= "pending";
    });
    builder.addCase(fetchSlider.fulfilled, (state, action) => {
      state.status = 'success';
      state.slider = action.payload;
    });
    builder.addCase(fetchSlider.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
});

export default topSliderSlice.reducer;

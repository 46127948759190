import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function TopNavbar() {
  const cartData = useSelector((state) => state.cart);
  const favorite = useSelector((state) => state.favorite);  
  const info = useSelector((state) => state.siteInfo?.info);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(()=>{
      setLoading(false)
    }, 500)
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <header className="px-6 py-2 md:px-10 2xl:px-20 sticky top-0 left-0 bg-[rgb(73_53_72)] text-white z-30">
    {/* <header className="px-6 py-2 md:px-10 2xl:px-20 bg-[rgb(73_53_72)] text-white sticky top-0 left-0 z-10"> */}
        <div className="sm:container p-1 sm:p-0 mx-auto">
            <div className="flex justify-between items-center text-sm">
                <div className="hidden sm:flex items-center gap-5">
                    <div className="flex items-center gap-2">
                        {
                          loading ? (
                            <>
                            <span className="icon-call text-sm" />
                            <div className="w-28 h-4 bg-slate-400 rounded"></div>
                            </>
                          ) : (
                            <>
                            <span className="icon-call text-sm" />
                            <a href={`tel:${info?.data?.phone}`}>{info?.data?.phone}</a>
                            </>
                          )
                        }
                    </div>
                    <div className="flex items-center gap-2">
                    {
                          loading ? (
                            <>
                            <span className="icon-mail text-sm" />
                            <div className="w-36 h-4 bg-slate-400 rounded"></div>
                            </>
                          ) : (
                            <>
                            <span className="icon-mail text-sm" />
                            <a href={`mailto:${info?.data?.email}`}>{info?.data?.email}</a>
                            </>
                          )
                        }
                        
                    </div>
                </div>
                <div className='flex gap-12'>
                    <div className="relative">
                      <a href='/favorite'><FavoriteBorderIcon />Favorite</a>
                      <span className='absolute top-[-6px] left-[-25px] bg-green-700 text-white rounded-full px-2 py-0'>{favorite?.favoriteItems?.length}</span>
                    </div>
                    <div className="relative">
                      <a href='/cart'><ShoppingBasketIcon />Cart</a>
                      <span className='absolute top-[-6px] left-[-25px] bg-green-700 text-white rounded-full px-2 py-0'>{cartData?.cartItems?.length}</span>
                    </div>
                    {/* <div className="flex items-center gap-1">
                        <span className="icon-car text-sm" />
                        <a href="/track">Track Your Order</a>
                    </div> */}
                </div>
            </div>
        </div>
    </header>
  )
}

export default TopNavbar

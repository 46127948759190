import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { app_url } from '../../../features/api';
import { addToCart } from '../../../features/cartSlice';
import { addToFavorite } from '../../../features/favoriteSlice';
import SkeletonCard from './SkeletonCard';
import styles from './Styles/Style.module.css';

const FavoriteCard = ({products, error, cartData, favorite}) => {
  const [activeBtn, setActiveBtn] = useState(false);
  const [circle, setCircle] = useState(true);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleMouseEnter = (id) => {
    setValue(id)
    setActiveBtn(true)
  };
  const containerClassName = `${styles.orderBtnInactive} ${activeBtn ? styles.orderBtnActive : ''}`;
  const circleClassName = `${styles.btnCircle} ${circle ? styles.circleOne : styles.circleTwo} `;

  useEffect(() => {
    const timer = setTimeout(()=>{
      setLoading(false)
    }, 500)
  
    return () => clearTimeout(timer);
  }, [])

  const [addedProducts, setAddedProducts] = useState([]);

  const [attributes, setAttributes] = useState([]);
  const [count, setCount] = useState(1);

  const handleAddToCart = (data) => {
    const value = {
      data,
      attributes,
      count
    }
    dispatch(addToCart(value));
    setAddedProducts([...addedProducts, data.id]);
  };

  const handleAddToFavorite = (data) => {
    dispatch(addToFavorite(data));
  };
  const oder = (id) => {
    navigate('/checkout')
  }

  const decreaseByPercentage = (number, percentage) => {
    const decreaseAmount = (percentage / 100) * number;
    const result = number - decreaseAmount;
    return result.toFixed(2);
  };
  
  return (
    <>
    {loading ? (
      Array(products?.length).fill(0).map((products, index)=><SkeletonCard key={index} />)
    ) : error ? (
      <p>An error occured...</p>
    ) :
    (
      <>
      {
        products?.length > 0 ? (
          <>
            {products?.map((data) => (
            <div
                key={data.id}
                className="w-60 rounded-md shadow-[0px_3px_3px_0_rgb(0,0,0,.15)] hover:shadow-[rgba(0,0,0,0.24)_0px_3px_8px]"
                onMouseEnter={() => handleMouseEnter(data.id)}
                onMouseLeave={() => setActiveBtn(false)}
            >
                <div className='relative'>
                    <img
                        className="w-full h-64 rounded-md"
                        src={`${app_url}/storage/${data.thumbnail}`}
                        alt={data.name}
                    />
                  {value==data.id &&
                  <div
                  onClick={() => oder(data.id)}
                    className={containerClassName}
                    onMouseEnter={() => setCircle(true)}
                    onMouseLeave={() => setCircle(false)}
                  >
                    <div className={circleClassName}></div>
                    <span>Order</span>
                  </div>}
                </div>
                <div className="p-4 flex flex-col gap-1.5">
                    <Link to={`/product-view/${data.slug}`}>{data.name}</Link>

                    <div className="flex justify-between">
                      <h5 className="font-normal text-sm text-gray-600">
                      {
                        data.discount ? 
                        <>{data.discount_type == 'percentage' ? <>{decreaseByPercentage(data.price, data.discount)} BDT</> : <>{data.price - data.discount} BDT </>}</> :
                        <>{data.price} BDT</> 
                      }
                      </h5>
                      {
                        data.discount ? (
                          <>
                          <p className="text-xs line-through bg-orange-400 rounded flex items-center px-1"> 
                            {data.discount} {data.discount_type == 'percentage' ? '%' : 'TK'}
                          </p>
                          </>
                        ) : ''
                      }
                    </div>
                    <div className="action flex gap-3">
                    {
                      data?.product_attributes?.length > 0 ? <>
                      {cartData?.map(item => item.id).includes(data.id) ? (
                        <button
                            type="button"
                            disabled
                            className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-slate-200  transition-all duration-500"
                        >
                            Already Added
                        </button>
                      ) : (
                        <>
                        <Link 
                        to={`/product-view/${data.slug}`}
                        className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-green-100 hover:bg-green-700 hover:text-white transition-all duration-500 flex items-center justify-center"
                        >View Details</Link>
                        </>
                      )}
                        
                      </> : <>
                      {cartData?.map(item => item?.data?.id).includes(data?.id) ? (
                        <button
                            type="button"
                            disabled
                            className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-slate-200  transition-all duration-500"
                        >
                            Already Added
                        </button>
                      ) : (
                        <>
                        <button
                            type="button"
                            className="text-xs flex-1 border border-gray-400 rounded-md py-1 bg-green-700 hover:bg-green-600 text-white transition-all duration-500"
                            onClick={() => handleAddToCart(data)}
                        >
                            Add to cart
                        </button>
                        </>
                      )}
                      </>
                    }
                    
                    
                      {favorite?.map(item => item.id).includes(data.id) ? (
                        <button
                          type="button"
                          className="text-lg border-none rounded-md py-1 px-1 text-red-600"
                          onClick={() => handleAddToFavorite(data)}
                        >
                          <FavoriteIcon />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="text-lg border-none rounded-md py-1 px-1 text-gray-500 hover:text-red-600"
                          onClick={() => handleAddToFavorite(data)}
                        >
                          <FavoriteBorderIcon />
                      </button>
                      )}          
                    </div>
                </div>
            </div>
            ))}
            </>
        ) : (
          <div className='flex items-center justify-center w-full h-72'>
            <div className='text-center'>
              <p className='text-md text-gray-600 text-xl'>item not found! 
              <span className='text-green-700'><SentimentSatisfiedAltIcon /></span> 
              {/* <Link className='underline cursor-pointer hover:text-green-700' to="/">Back to Shopping</Link> */}
              </p>
            </div>
          </div>
        )
      }
      </>
    )}
    </>
  )
};

export default FavoriteCard
